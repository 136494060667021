export const withMock = false; // true

export function getFundFollowUp(
  soAfpBaseUrl: string,
  proceesId: string
): string {
  return soAfpBaseUrl + `workflows/${proceesId}/processes`;
}

export function getUserFunds(
  soAfpBaseUrl: string,
  pageNumber: number,
  bdrID?: string,
  isInternalUser?: boolean
): string {
  if (bdrID) {
    const endpoint = isInternalUser ? 'fundsByBdr' : 'fundsByMails';
    return (
      soAfpBaseUrl +
      `${endpoint}?pageSize=5&pageNumber=${pageNumber}&bdrId=${bdrID}`
    );
  } else
    return soAfpBaseUrl + `fundsByMails?pageSize=5&pageNumber=${pageNumber}`;
}
