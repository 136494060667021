import React, { useEffect, useState } from "react";

import {
  widgetize,
  WidgetPropsMapping,
  WithCss,
  useWidgetBusData,
  useWidgetConfiguration,
} from "@sg-widgets/react-core";
import {
  Analytics,
  AnalyticCallback,
  List,
  NoDataFiller,
  ViewSelector,
} from "@sgss-ttd-widgets/components";
import * as listStyles from "@sgss-ttd-widgets/components/styles/list.less";
import { useSgConnectFetch } from "@sg-widgets/react-core";
import * as widgetStyles from "./so-fund-follow-up.less";

import {
  ActionTemplate,
  ToolBarTemplate,
  TitleTemplate,
  BodyTemplate,
} from "./components";
import { format, parseISO } from "date-fns";
import { FundFollowUp } from "./model/fund-follow-up.model";
import { fetchData, getTranslatedValue } from "./components/fetchData";
import { ClientPortalInput } from "../common/client-model";
import { BUS_USER_CONNECTION } from "../common/bus-topics";
import { getSoAfpBaseUrl } from "../common/api-url-provider";
import { ServiceLocator } from "../common/services/service-locator";
import { SoService } from "../common/services/so-service";
import { getMergedStyles } from "../common/utils";

interface Props {
  items: FundFollowUp[];
  hasUnauthorizedAccess: boolean;
  hasDataFetchingError: boolean;
  isLoading: boolean;
  itemClick: (data: { item: FundFollowUp }) => void;
  navigationRequest: (url: string) => void;
  helpClick: () => void;
  onPageChanged: () => (data: { item: number }) => void;
  pageNumber: number;
  bdrId: string;
  isInternalUser: boolean;
  clientPortalInput: any;
  analyticCallback: AnalyticCallback;
}

const SoFundFollowUp: React.FC<Props> = ({
  items,
  hasUnauthorizedAccess,
  hasDataFetchingError,
  isLoading,
  pageNumber,
  bdrId,
  isInternalUser,
  itemClick,
  navigationRequest,
  helpClick,
  onPageChanged,
  clientPortalInput = new ClientPortalInput(),
  analyticCallback,
}) => {
  const [soAfpBaseUrl, setSoAfpBaseUrl] = useState<string>("");
  const [soUrl, setsoUrl] = useState<string>("");
  const conf: any = useWidgetConfiguration();
  const itemTitlePropertyPath = "name";
  const [itemsState, setItemsState] = useState(items);
  const [hasUnauthorizedAccessState, setHasUnauthorizedAccessState] = useState(
    hasUnauthorizedAccess
  );
  const [hasDataFetchingErrorState, setHasDataFetchingErrorState] = useState(
    hasDataFetchingError
  );
  const [isLoadingState, setIsLoadingState] = useState(true);
  const [expiredFundsCountState, setExpiredFundsCountState] = useState(0);
  const [currentPage, setCurrentPage] = useState(pageNumber);
  const [totalPages, setTotalPages] = useState(4);

  const [user, setUser] = React.useState<any>({});

  const userBus = useWidgetBusData<object>(BUS_USER_CONNECTION);

  const langID = useWidgetBusData<string>("global.language");

  const { fetch } = useSgConnectFetch("api.client-template.v1");

  const createPayloadSeeAllClick = Analytics.payloadFactory(
    "buttonClick",
    "Click to see all funds"
  );
  const createPayloadCreateClick = Analytics.payloadFactory(
    "buttonClick",
    "Click to create a fund"
  );
  const createPayloadDuplicateClick = Analytics.payloadFactory(
    "buttonClick",
    "Click to duplicate a fund"
  );
  const createPayloadItemClickButton = Analytics.payloadFactory(
    "buttonClick",
    "Click on the button to open a fund"
  );
  const createPayloadItemClickRow = Analytics.payloadFactory(
    "buttonClick",
    "Click on a row to open a fund"
  );
  const createHelpClick = Analytics.payloadFactory(
    "buttonClick",
    "Click to see help center"
  );

  useEffect(() => {
    getSoAfpBaseUrl(conf).then(res => {
      setSoAfpBaseUrl(res);
    });
  }, []);

  useEffect(() => {
    getSoUrl(conf).then(res => {
      setsoUrl(res);
    });
  }, []);

  const busUserCallBack = (user?: object): void => {
    if (user) {
      setUser(user);
    }
  };

  useEffect(() => {
    busUserCallBack(userBus);
  });

  useEffect(() => {
    if (items !== itemsState) {
      setItemsState(items);
    }
  }, [items]);

  useEffect(() => {
    if (hasUnauthorizedAccess !== hasUnauthorizedAccessState) {
      setHasUnauthorizedAccessState(hasUnauthorizedAccess);
    }
  }, [hasUnauthorizedAccess]);

  useEffect(() => {
    if (hasDataFetchingError !== hasDataFetchingErrorState) {
      setHasDataFetchingErrorState(hasDataFetchingError);
    }
  }, [hasDataFetchingError]);

  useEffect(() => {
    if (
      isLoading !== undefined &&
      isLoading !== null &&
      isLoading !== isLoadingState
    ) {
      setIsLoadingState(isLoading);
    }
  }, [isLoading]);

  useEffect(() => {
    if (user && user.mail !== undefined && soAfpBaseUrl) {
      if (pageNumber != currentPage) {
        setCurrentPage(pageNumber);
      }
      loadData();
    }
  }, [user, soAfpBaseUrl, pageNumber]);

  const getSoAfpBaseUrl = async widgetConfig => {
    ServiceLocator.register(SoService.soBaseUrlServiceName, SoService);
    const res = await ServiceLocator.get<SoService>(
      SoService.soBaseUrlServiceName
    ).fetchSoAfpBaseUrl(widgetConfig);
    return res;
  };
  const getSoUrl = async widgetConfig => {
    ServiceLocator.register(SoService.soUrlServiceName, SoService);
    const res = await ServiceLocator.get<SoService>(
      SoService.soUrlServiceName
    ).fetchSoUrl(widgetConfig);
    return res;
  };

  const processData = (funds: FundFollowUp[]): FundFollowUp[] => {
    return funds?.map(item => {
      const creationDate = parseISO(item?.creationDate);
      const launchDate = parseISO(item?.launchDate);
      const currentDate = new Date();
      return {
        ...item,
        isExpired: launchDate < currentDate,
        formattedCreationDate:
          getTranslatedValue("STARTED", langID) +
          ` ${format(creationDate, "dd MMM yyyy")}`,
      };
    });
  };

  const loadData = async () => {
    setIsLoadingState(true);

    const fundsData = await fetchData(
      fetch,
      soAfpBaseUrl,
      pageNumber,
      undefined,
      bdrId,
      isInternalUser
    );
    const { status, data } = fundsData;

    setHasUnauthorizedAccessState(false);
    setHasDataFetchingErrorState(false);
    setIsLoadingState(false);
    setExpiredFundsCountState(0);

    if (status === "notauthenticated" || status === "forbidden") {
      setHasUnauthorizedAccessState(true);
      return;
    }

    if (status !== "loaded") {
      setHasDataFetchingErrorState(true);
      return;
    }

    const { values, expiredFundsCount, totalPages } = data;
    const processedFunds = processData(values);
    setItemsState(processedFunds);
    setExpiredFundsCountState(expiredFundsCount);

    if (totalPages <= 4) {
      setTotalPages(totalPages);
    } else {
      setTotalPages(4);
    }
  };

  const RefreshActionTemplate = (): JSX.Element => (
    <button
      className="btn btn-default btn-lg btn-icon-start"
      onClick={() => {
        loadData();
      }}
    >
      <i className="icon">refresh</i>Retry
    </button>
  );

  const actionsTemplate = <RefreshActionTemplate />;
  const content = (
    <ViewSelector
      isLoading={isLoadingState}
      itemsCount={itemsState?.length > 0 ? itemsState?.length : -1}
      hasUnauthorizedAccess={hasUnauthorizedAccessState}
      hasDataFetchingError={hasDataFetchingErrorState}
      actionsTemplate={actionsTemplate}
      unauthorizedAccessFiller={
        <NoDataFiller title="Unauthorized access" icon="block">
          <span>
            {
              'You cannot access business implementation with the "Navigate as" option.'
            }
          </span>
        </NoDataFiller>
      }
    >
      <List
        titleTemplate={
          <TitleTemplate
            headerTitleText={getTranslatedValue("FUNDS_ONBOARDING", langID)}
          />
        }
        onTitleClick={() => {
          navigationRequest(`${soUrl}funds`);
          analyticCallback(createPayloadSeeAllClick());
        }}
        actionsTemplate={
          <ActionTemplate
            helpClick={() => {
              helpClick();
              analyticCallback(createHelpClick());
            }}
            helpText={getTranslatedValue("HELP", langID)}
          />
        }
        toolbarTemplate={
          !isInternalUser ? (
            <ToolBarTemplate
              createClick={() => {
                navigationRequest(`${soUrl}funds/creation`);
                analyticCallback(createPayloadCreateClick());
              }}
              duplicateClick={() => {
                navigationRequest(`${soUrl}funds/duplicatefunds`);
                analyticCallback(createPayloadDuplicateClick());
              }}
              createText={getTranslatedValue("CREATE", langID)}
              duplicateText={getTranslatedValue("DUPLICATE", langID)}
              isFundsAvailable={itemsState?.length > 0 ? true : false}
            />
          ) : undefined
        }
        items={itemsState}
        bodyTemplate={
          itemsState?.length > 0 ? (
            <BodyTemplate
              items={itemsState}
              soFundFollowUpUrl={item => {
                navigationRequest(`${soUrl}funds/fund-follow-up/${item.id}`);
                analyticCallback(createPayloadItemClickButton(item.name));
              }}
              language={langID?.toUpperCase()}
            />
          ) : (
            <NoDataFiller
              title={getTranslatedValue("NOWORKFLOW_TITLE", langID)}
              icon="widgets"
            >
              {getTranslatedValue("NOWORKFLOW_DETAILS", langID)}
            </NoDataFiller>
          )
        }
        itemHighlightPredicate={() => true}
        onClick={data => {
          itemClick(data);
          analyticCallback(createPayloadItemClickRow(data.item.name));
        }}
        noBorder={true}
        pages={totalPages}
        currentPage={pageNumber}
        onPageChange={item => {
          onPageChanged(item);
        }}
        isLoading={isLoadingState}
      />
    </ViewSelector>
  );

  const styles = getMergedStyles(listStyles, widgetStyles);
  return (
    <WithCss styles={styles}>
      <div className="wrapper d-lg-flex flex-column bg-lvl1 ">{content}</div>
    </WithCss>
  );
};

widgetize("so-fund-follow-up", SoFundFollowUp, {
  items: WidgetPropsMapping.asObject({
    description: "The items",
  }),
  hasUnauthorizedAccess: WidgetPropsMapping.asObject({
    description: "Indicates wether the access in authorized or not",
  }),
  hasDataFetchingError: WidgetPropsMapping.asObject({
    description:
      "Indicates if there was an error when trying to fetch the data",
  }),
  isLoading: WidgetPropsMapping.asObject({
    description: "Indicates if there is a load operation pending",
  }),
  pageNumber: WidgetPropsMapping.asNumber({
    description: "Indicates the page number to be loaded",
  }),
  bdrId: WidgetPropsMapping.asString({
    description: "Indicates the bdr Id of client",
  }),
  isInternalUser: WidgetPropsMapping.asObject({
    description: "Indicates if the user is internal",
  }),
  itemClick: WidgetPropsMapping.asEventEmitter("item-click", {
    description:
      "Event occuring when an item is clicked with data containing the item payload",
  }),
  navigationRequest: WidgetPropsMapping.asEventEmitter("navigation-request", {
    description: "Event occuring when the widget requests a navigation",
  }),
  helpClick: WidgetPropsMapping.asEventEmitter("help-click", {
    description: "Event occuring when the help is clicked",
  }),
  onPageChanged: WidgetPropsMapping.asEventEmitter("on-page-changed", {
    description: "Event occuring when on page changed",
  }),
  clientPortalInput: WidgetPropsMapping.asObject({
    description: "client details",
  }),
  analyticCallback: WidgetPropsMapping.asEventEmitter("analytic-callback", {
    description:
      "Event occurring when a user action should be logged to the analytic module for monitoring",
  }),
});
