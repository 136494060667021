import { fetchAsync } from "./fetchAsync";
import { FundFollowUp } from "../model/fund-follow-up.model";
import { getFundFollowUp, getUserFunds } from "../../common/config";

const buildRequest = (url: string) => ({
  url: url,
  method: "GET",
  headers: {
    NavigateAs:
      JSON.parse(
        sessionStorage.getItem("SGM.Securities.Services:navigateAsUser") || "{}"
      ) !== null &&
      JSON.parse(
        sessionStorage.getItem("SGM.Securities.Services:navigateAsUser") || "{}"
      ).email !== undefined
        ? JSON.parse(
            sessionStorage.getItem("SGM.Securities.Services:navigateAsUser") ||
              "{}"
          ).email
        : "",
  },
});

const executeRequest = (
  fetch: any,
  url: string,
  cleanValues: any[] | undefined
): Promise<any> => {
  return new Promise<any>(resolve => {
    const onSuccess = (response: Response) => {
      if (response.status === 401) {
        resolve({
          status: "notauthenticated",
          data: null,
        });
        return;
      } else if (response.status === 204 || response.status === 404) {
        resolve({
          status: "notfound",
          data: null,
        });
        return;
      } else if (response.status === 403) {
        resolve({
          status: "forbidden",
          data: null,
        });
        return;
      }
      if (response.body) {
        response.json().then(
          body =>
            resolve({
              status: "loaded",
              data: body,
            }),
          () =>
            resolve({
              status: "failed",
              data: null,
            })
        );
        return;
      }
      resolve({
        status: "failed",
        data: null,
      });
    };

    fetchAsync({
      fetch,
      requestInfos: buildRequest(url),
      onSuccess: onSuccess,
      onError: () =>
        resolve({
          status: "failed",
          data: null,
        }),
      canFetch: () => true,
      status: null,
      cleanValues,
    });
  });
};

export const fetchData = async (
  fetch,
  soAfpBaseUrl: string,
  pageNumber: number,
  cleanValues: any[] | undefined,
  bdrId?: string,
  isInternalUser?: boolean
): Promise<{ status: string; data: any }> => {
  return new Promise<any>(async resolve => {
    const fundsResult = await executeRequest(
      fetch,
      getUserFunds(soAfpBaseUrl, pageNumber, bdrId, isInternalUser),
      cleanValues
    );
    const { data, status } = fundsResult;
    const funds: FundFollowUp[] = data?.values;

    if (status !== "loaded") {
      return resolve(fundsResult);
    }

    if (!data) {
      resolve({ status: "notfound", data: null });
    }

    const processInstanceIdToFund = funds.reduce(
      (acc: { [key: string]: FundFollowUp }, cur) => {
        acc[cur.processInstanceId] = cur;
        return acc;
      },
      {}
    );

    let hasError = false;
    const detailPromises = funds.map(fund =>
      executeRequest(
        fetch,
        getFundFollowUp(soAfpBaseUrl, fund.processInstanceId),
        cleanValues
      ).then(fundDetailResult => {
        const { status, data } = fundDetailResult;
        if (status !== "loaded") {
          hasError = true;
        } else {
          processInstanceIdToFund[fund.processInstanceId].fundWorkflowSteps =
            data?.workflowFollowUps;
        }
      })
    );

    await Promise.all(detailPromises);

    if (hasError) {
      resolve({ status: "failed", data: fundsResult.data });
    }

    resolve(fundsResult);
  });
};

export const getTranslatedValue = (
  stage: string,
  selectedLanguage: any
): string => {
  if (stage == "NOWORKFLOW_TITLE") {
    return selectedLanguage?.toUpperCase() == "FR"
      ? "Pas de processus"
      : "No workflows";
  }
  if (stage == "NOWORKFLOW_DETAILS") {
    return selectedLanguage?.toUpperCase() == "FR"
      ? "Vous n’avez pas de processus"
      : "You do not have any workflow";
  }
  if (stage == "BUSINESS_TITLE") {
    return selectedLanguage?.toUpperCase() == "FR"
      ? "Mise en œuvre des services"
      : "Business implementation";
  }
  if (stage == "SEE_ALL") {
    return selectedLanguage?.toUpperCase() == "FR" ? "Voir tout" : "See all";
  }
  if (stage == "DUPLICATE") {
    return selectedLanguage?.toUpperCase() == "FR"
      ? "Dupliquer un processus"
      : "Duplicate a process";
  }
  if (stage == "CREATE") {
    return selectedLanguage?.toUpperCase() == "FR" ? "Créer" : "Create";
  }
  if (stage == "STARTED") {
    return selectedLanguage?.toUpperCase() == "FR" ? "Commencé" : "Started";
  }
  if (stage == "EXPECTED")
    return selectedLanguage?.toUpperCase() == "FR" ? "Prévu" : "Expected";
  if (stage == "HELP") {
    return selectedLanguage?.toUpperCase() == "FR" ? "Aide" : "Help";
  }
  if (stage == "FUNDS_ONBOARDING") {
    return selectedLanguage?.toUpperCase() == "FR"
      ? "Lancement des fonds"
      : "Funds onboarding";
  }

  return "";
};

//export default fetchData;
